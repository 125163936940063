export const color = {
  white: '#ffffff',
  black: '#000000',
  gray: {
    alabaster: '#f7f7f7',
    gallery: '#eaeaea',
    alto: '#d8d8d8',
    edward: '#A4B0AD',
  },
  green: {
    persian: '#00AF85',
    aquaSqueeze: '#f2f9f7',
  },
};

export const mainContainerWidth = {
  desktop: 1180,
};

export const horizontalPadding = 15;

export const breakpoint = {
  desktop: 1024,
  tablet: 768,
  mobile: 425,
};

export const zIndex = {
  header: 10000,
  blog: {
    header: 5000,
  },
  map: 5000,
  body: 6000,
};
