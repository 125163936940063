import styled, { css } from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { breakpoint, color } from 'common/styledVars';

export const baseFontStyle = css`
  font-size: 14px;
  font-weight: 300;

  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 16px;
  }
`;

export const BaseP = styled.p`
  ${baseFontStyle};
  color: ${color.green.persian};
`;

export const BaseTransparentP = styled.p`
  ${baseFontStyle};
  color: #9db1ad;
  line-height: 35px;
`;

export const ScholarHeader = styled.h3`
  font-size: 26px;
  font-weight: 300;
  line-height: 33px;
`;

export const HeaderLarge = styled.h1`
  font-size: 28px;
  font-weight: 500;

  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 48px;
    line-height: 61px;
  }
`;

export const HeaderMedium = styled.h2`
  font-size: 28px;
  font-weight: 500;

  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 36px;
  }
`;

export const HeaderSmall = styled.h3`
  opacity: 0.9;
  font-size: 21px;
  font-weight: 500;

  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 26px;
  }
`;

export const navigationText = css`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 20px;
    line-height: 35px;
  }
`;

export const MediumText = styled.div`
  ${navigationText};
`;

export const MediumTextTransparent = styled.div`
  ${navigationText};
  color: #a4b0ad;
`;
